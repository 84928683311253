<template>
  <v-container
    id="clienti"
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
    :class="clienti.length == 0 ? 'd-flex justify-center align-center' : ''"
  >
    <v-row>
      <v-col cols="12">
        <!-- NUOVO CLIENTE -->
        <div class="text-center" v-if="clienti.length == 0">
          <h2 class="mb-2">Crea il primo cliente</h2>

          <v-btn icon @click="dialog = true">
            <v-icon large>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>

        <!-- TABELLA -->
        <v-data-table
          v-if="clienti.length != 0"
          :headers="headers"
          :items="clienti"
          :items-per-page="itemsPerPage"
          sort-by="rag_soc"
          :sort-desc="false"
          :search="search"
          :custom-filter="filterAll"
          multi-sort
          no-results-text="Nessun risultato"
          fixed-header
          :height="
            $vuetify.breakpoint.xs
              ? 'calc(100vh - 331px)'
              : 'calc(100vh - 339px)'
          "
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }"
          @current-items="getData"
        >
          <template v-slot:top>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                class="search"
                :clearable="true"
              ></v-text-field>

              <!-- FILTER -->
              <v-dialog v-model="filterDialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-show="!checkFilters"
                    ><v-icon @click="clearFilter()"
                      >mdi-filter-remove-outline</v-icon
                    ></v-btn
                  >
                  <v-btn icon
                    ><v-icon v-bind="attrs" v-on="on"
                      >mdi-filter-outline</v-icon
                    ></v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Filtra per:</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="ragSoc"
                            item-text="text"
                            item-value="value"
                            label="Ragione Sociale"
                            v-model="filtersObj.rag_soc"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.indirizzo"
                            label="Indirizzo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.CAP"
                            label="CAP"
                            maxlength="5"
                            counter="5"
                            @keypress="isInteger($event)"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            :rules="[
                              (v) =>
                                /^(\s*|\d+)$/.test(v) || 'Inserire solo numeri',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="locList"
                            item-text="text"
                            item-value="value"
                            label="Località"
                            v-model="filtersObj.loc"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="provList"
                            item-text="text"
                            item-value="value"
                            label="Provincia"
                            v-model="filtersObj.prov"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.tel"
                            label="Telefono"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.email"
                            label="Email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions
                    :class="$vuetify.breakpoint.xs ? 'flex-wrap' : ''"
                  >
                    <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
                    <v-btn color="blue darken-1" text @click="clearFilter()">
                      Cancella tutto
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="filterDialog = false"
                    >
                      Chiudi
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="filterDialog = false"
                      :class="$vuetify.breakpoint.xs ? 'ml-0' : ''"
                    >
                      Conferma
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
              <v-btn icon @click="dialog = true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-card-title>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              class="mr-2"
              @click="openMaps(item)"
              >mdi-map-marker
            </v-icon>
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              class="mr-2"
              @click="modificaCliente(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              @click="cancellaCliente(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="!$vuetify.breakpoint.xs"
            >
              Esporta
              <v-icon right> mdi-download </v-icon>
            </v-btn>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="$vuetify.breakpoint.xs"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template></v-data-table
        >

        <!-- DIALOG AGGIUNGI/MODIFICA -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.rag_soc"
                        label="Ragione Sociale"
                        placeholder="New Faemac"
                        required
                        maxlength="50"
                        counter="50"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.indirizzo"
                        label="Indirizzo"
                        placeholder="Via dell'Artigianato 19"
                        required
                        maxlength="50"
                        counter="50"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.CAP"
                        label="CAP"
                        placeholder="20835"
                        maxlength="5"
                        counter="5"
                        inputmode="numeric" 
                        pattern="[0-9]*"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            /^(\s*|\d+)$/.test(v) || 'Inserire solo numeri',
                          (v) => !v || v.length == 5 || 'Valore non valido',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.localita"
                        label="Località"
                        placeholder="Muggiò"
                        required
                        maxlength="30"
                        counter="30"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.provincia"
                        label="Provincia"
                        placeholder="MB"
                        maxlength="2"
                        counter="2"
                        :rules="[
                          (v) =>
                            !v ||
                            /^[A-Z]+$/.test(v) ||
                            'Inserire solo lettere maiuscole',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.telefono"
                        label="Telefono"
                        placeholder="1234567890"
                        maxlength="10"
                        counter="10"
                        inputmode="numeric" 
                        pattern="[0-9]*"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            !v ||
                            /^(\s*|\d+)$/.test(v) ||
                            'Inserire solo numeri',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.email"
                        label="Email"
                        placeholder="info@newfaemac.it"
                        maxlength="50"
                        counter="50"
                        :rules="[
                          (v) =>
                            !v || /.+@.+\..+/.test(v) || 'E-mail non valida',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.latitudine"
                        label="Latitudine"
                        placeholder="45.58649521330993"
                        required
                        maxlength="19"
                        counter="19"
                        :rules="[
                          (v) => !!v || 'Campo obbligatorio',
                          (v) => CheckPunti(v) || 'Valore non valido',
                          (v) => CheckDash(v) || 'Valore non valido',
                          (v) =>
                            CheckDecimali(v, 15) ||
                            'Numero di decimali non valido',
                          (v) =>
                            (parseFloat(v) <= 90 && parseFloat(v) >= -90) ||
                            'Il valore deve essere tra -90 e 90',
                          (v) => CheckCoordinates(v) || 'Valore non valido',
                          (v) =>
                            /^[0-9.-]+$/.test(v) || 'Caratteri non accettati',
                        ]"
                        @keypress="isFloat($event)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedClient.longitudine"
                        label="Longitudine"
                        placeholder="9.23525041314301"
                        required
                        maxlength="20"
                        counter="20"
                        :rules="[
                          (v) => !!v || 'Campo obbligatorio',
                          (v) => CheckPunti(v) || 'Valore non valido',
                          (v) => CheckDash(v) || 'Valore non valido',
                          (v) =>
                            CheckDecimali(v, 15) ||
                            'Numero di decimali non valido',
                          (v) =>
                            (parseFloat(v) <= 180 && parseFloat(v) >= -180) ||
                            'Il valore deve essere tra -180 e 180',
                          (v) => CheckCoordinates(v) || 'Valore non valido',
                          (v) =>
                            /^[0-9.-]+$/.test(v) || 'Caratteri non accettati',
                        ]"
                        @keypress="isFloat($event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">
                Chiudi
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!valid"
                :loading="buttonLoading"
                @click="saveClient()"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- DELETE DIALOG -->
        <v-dialog v-model="dialogDelete" max-width="550px" persistent>
          <v-card>
            <v-card-title
              :class="
                $vuetify.breakpoint.xs
                  ? 'subtitle-1 justify-center text-center delete'
                  : 'text-h6 justify-center text-center delete'
              "
              style="word-break: break-word"
              >Sei sicuro di voler cancellare il cliente "{{
                editedClient.rag_soc
              }}"?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete()"
                >Chiudi</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="cancellaClienteConferma()"
                :loading="buttonLoading"
                >Conferma</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- EXPORT DIALOG -->
        <v-dialog v-model="dialogCSV" max-width="500px">
          <v-card>
            <v-card-title
              :class="$vuetify.breakpoint.xs ? 'text-h6 pt-4' : 'headline pt-4'"
              >Download CSV</v-card-title
            >
            <v-card-text>
              <div class="mt-4">
                <p style="display: inline">File .csv pronto!</p>
                <a
                  :download="'Clienti-' + dateNow + '.csv'"
                  :href="fileCSV"
                  style="text-decoration: none; display: inline"
                  class="ml-4"
                >
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="$vuetify.breakpoint.xs"
                  >
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="!$vuetify.breakpoint.xs"
                  >
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogCSV = false"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Clienti",
  data() {
    return {
      search: "",
      clienti: [],
      headers: [
        {
          text: "Ragione Sociale",
          align: "start",
          value: "rag_soc",
          filter: (value) => {
            if (this.filtersObj.rag_soc.length == 0) return true;
            return this.filtersObj.rag_soc.includes(value);
          },
        },
        {
          text: "Indirizzo",
          value: "indirizzo",
          filter: (value) => {
            if (!this.filtersObj.indirizzo) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.indirizzo.toLowerCase());
            }
          },
        },
        {
          text: "CAP",
          value: "CAP",
          filter: (value) => {
            if (!this.filtersObj.CAP) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.CAP.toLowerCase());
            }
          },
        },
        {
          text: "Località",
          value: "localita",
          filter: (value) => {
            if (this.filtersObj.loc.length == 0) return true;
            return this.filtersObj.loc.includes(value);
          },
        },
        {
          text: "Provincia",
          value: "provincia",
          width: "10%",
          filter: (value) => {
            if (this.filtersObj.prov.length == 0) return true;
            return this.filtersObj.prov.includes(value);
          },
        },
        {
          text: "Telefono",
          value: "telefono",
          filter: (value) => {
            if (!this.filtersObj.tel) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.tel.toLowerCase());
            }
          },
        },
        {
          text: "Email",
          value: "email",
          filter: (value) => {
            if (!this.filtersObj.email) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.email.toLowerCase());
            }
          },
        },
        { text: "Azioni", value: "actions", sortable: false, width: "10%" },
      ],
      itemsPerPage: 15,
      ragSoc: [],
      provList: [],
      locList: [],
      filtersObj: {
        rag_soc: [],
        indirizzo: "",
        CAP: "",
        loc: [],
        prov: [],
        tel: "",
        email: "",
      },
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      filterDialog: false,
      alert: false,
      message: "",
      valid: false,
      defaultClient: {
        Id: "",
        rag_soc: "",
        indirizzo: "",
        CAP: "",
        localita: "",
        provincia: "",
        telefono: "",
        email: "",
        latitudine: "",
        longitudine: "",
      },
      editedClient: {
        Id: "",
        rag_soc: "",
        indirizzo: "",
        CAP: "",
        localita: "",
        provincia: "",
        telefono: "",
        email: "",
        latitudine: "",
        longitudine: "",
      },
      buttonLoading: false,
      dialogCSV: false,
      currentItems: [],
      fileCSV: null,
      dateNow: "",
    };
  },
  mounted() {
    this.$store.state.loading = true;
    this.getClienti();
  },
  computed: {
    checkFilters() {
      const isEmpty = Object.values(this.filtersObj).every(
        (x) =>
          x === null ||
          x === "" ||
          x.length === 0 ||
          (x[0] === null && x[1] === null)
      );

      if (isEmpty) {
        return true;
      } else {
        return false;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo cliente" : "Modifica cliente";
    },
  },
  methods: {
    getClienti() {
      this.axios
        .get(`${this.$store.state.url}clienti`, {
          params: {
            id_utente: localStorage.getItem("user"),
          },
        })
        .then((response) => {
          this.clienti = response.data.clienti;
          this.clienti.forEach((el) => {
            if (!this.ragSoc.includes(el["rag_soc"]) && el["rag_soc"] != "") {
              this.ragSoc.push(el["rag_soc"]);
            }

            if (
              !this.provList.includes(el["provincia"]) &&
              el["provincia"] != ""
            ) {
              this.provList.push(el["provincia"]);
            }

            if (
              !this.locList.includes(el["localita"]) &&
              el["localita"] != ""
            ) {
              this.locList.push(el["localita"]);
            }
          });

          this.$store.state.loading = false;
          this.search = this.$store.state.searchClienti;
          this.filtersObj = this.$store.state.filtersClienti;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    filterAll(valore, search, item) {
      var results = [];
      var columns = [];

      this.headers.forEach((element) => {
        columns.push(element.value);
      });

      Object.entries(item).forEach(([key, value]) => {
        if (
          value != undefined &&
          value != null &&
          value != "" &&
          columns.includes(key) &&
          search != null &&
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
        ) {
          results.push("true");
        }
      });
      return results.includes("true");
    },
    clearFilter() {
      this.filtersObj = {
        rag_soc: [],
        indirizzo: "",
        CAP: "",
        loc: [],
        prov: [],
        tel: "",
        email: "",
      };
    },
    openMaps(item) {
      var url = `https://maps.google.com/?q=${item.latitudine},${item.longitudine}`;
      window.open(url, "_blank");
    },
    modificaCliente(item) {
      this.editedIndex = this.clienti.indexOf(item);
      this.editedClient = Object.assign({}, item);
      // this.acceptNumber();
      this.dialog = true;
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloat(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 45) {
        return true;
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    CheckPunti(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var result = [];
      var regcheckDots = /[.]/g;
      result = value.match(regcheckDots);
      if (result != null) {
        if (result.length > 1) return false;
        else return true;
      }
      return true;
    },
    CheckDash(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var result = [];
      var index = -1;
      var regcheckDots = /[-]/g;
      index = value.indexOf("-");
      result = value.match(regcheckDots);
      if (result != null || index != -1) {
        if (index != 0 || result.length > 1) return false;
        else return true;
      }
      return true;
    },
    CheckDecimali(value, num) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var dec = value.split(".")[1];
      if (dec === null || dec === undefined || dec === "") return true;
      if (dec.length > num) return false;
      else return true;
    },
    CheckCoordinates(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var int = value.split(".")[0];
      if (int === null || int === undefined || int === "" || int === "-")
        return false;
      else return true;
    },
    acceptNumber() {
      if (
        this.editedClient["telefono"] != "" &&
        this.editedClient["telefono"] != null &&
        this.editedClient["telefono"] != undefined
      ) {
        var x = this.editedClient["telefono"]
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.editedClient["telefono"] = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    },
    close() {
      this.dialog = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$nextTick(() => {
        this.editedClient = Object.assign({}, this.defaultClient);
        this.editedIndex = -1;
      });
    },
    saveClient() {
      this.buttonLoading = true;
      this.axios
        .post(`${this.$store.state.url}clienti`, {
          id_utente: localStorage.getItem("user"),
          modifica_cliente: this.editedIndex,
          id_cliente: this.editedClient["Id"],
          rag_soc: this.editedClient["rag_soc"],
          indirizzo: this.editedClient["indirizzo"],
          CAP:
            this.editedClient["CAP"] == undefined
              ? ""
              : this.editedClient["CAP"],
          loc: this.editedClient["localita"],
          prov:
            this.editedClient["provincia"] == undefined
              ? ""
              : this.editedClient["provincia"],
          tel:
            this.editedClient["telefono"] == undefined
              ? ""
              : this.editedClient["telefono"],
          email:
            this.editedClient["email"] == undefined
              ? ""
              : this.editedClient["email"],
          lat: this.editedClient["latitudine"],
          long: this.editedClient["longitudine"],
        })
        .then(() => {
          this.buttonLoading = false;
          this.dialog = false;
          this.getClienti();
          if (this.editedIndex == -1) {
            this.$root.Snackbar.show({
              text: `Il cliente "${this.editedClient["rag_soc"]}" è stato creato`,
            });
          } else {
            this.$root.Snackbar.show({
              text: `Il cliente "${this.editedClient["rag_soc"]}" è stato modificato`,
            });
          }
          this.close();
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.dialog = false;
          this.close();
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    cancellaCliente(item) {
      this.editedIndex = this.clienti.indexOf(item);
      this.editedClient = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$nextTick(() => {
        this.editedClient = Object.assign({}, this.defaultClient);
        this.editedIndex = -1;
      });
    },
    cancellaClienteConferma() {
      this.buttonLoading = true;
      this.axios
        .delete(`${this.$store.state.url}clienti`, {
          params: {
            id_utente: localStorage.getItem("user"),
            id_cliente: this.editedClient["Id"],
          },
        })
        .then(() => {
          this.buttonLoading = false;
          this.dialogDelete = false;
          this.getClienti();
          this.$root.Snackbar.show({
            text: `Il cliente "${this.editedClient["rag_soc"]}" è stato cancellato`,
          });
          this.closeDelete();
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.dialogDelete = false;
          this.closeDelete();
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    getData(e) {
      this.currentItems = e;
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;
      this.$nextTick(() => {
        csv += `"Ragione sociale";"Indirizzo";"CAP";"Località";"Provincia";"Telefono";"Email"\n`;
        this.currentItems.forEach(function (row) {
          csv += `"${row["rag_soc"]}";"${row["indirizzo"]}";"${
            row["CAP"] == undefined ? "" : row["CAP"]
          }";"${row["localita"]}";"${
            row["provincia"] == undefined ? "" : row["provincia"]
          }";"${row["telefono"] == undefined ? "" : row["telefono"]}";"${
            row["email"] == undefined ? "" : row["email"]
          }";\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
        this.itemsPerPage = itemsBuffer;
      });
    },
  },
  watch: {
    search: function () {
      this.$store.state.searchClienti = this.search;
    },
    filtersObj: function () {
      this.$store.state.filtersClienti = this.filtersObj;
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style>
#clienti .search {
  padding: 0;
  margin: 0;
}
</style>