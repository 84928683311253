<template>
  <v-container
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
    id="note"
  >
    <v-row>
      <v-col cols="12">
        <div class="text-center" v-if="note.length == 0">
          <h2 class="mb-2">Nessuna nota ricevuta</h2>
        </div>

        <!-- TABELLA -->
        <v-data-table
          v-if="note.length != 0"
          :headers="headers"
          :items="note"
          :items-per-page="itemsPerPage"
          sort-by="Id"
          :sort-desc="true"
          :search="search"
          multi-sort
          no-results-text="Nessun risultato"
          fixed-header
          :height="
            $vuetify.breakpoint.xs
              ? 'calc(100vh - 306px)'
              : 'calc(100vh - 339px)'
          "
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }"
          @current-items="getData"
          class="row-pointer"
          @click:row="openImage"
        >
          <template v-slot:top>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                class="search"
                :clearable="true"
              ></v-text-field>

              <v-spacer></v-spacer>
            </v-card-title>
          </template>

          <!-- COLONNE DELLA TABELLA -->
          <template v-slot:[`item.letta`]="{ item }">
            <v-avatar
              :color="getColor(item)"
              dark
              size="12"
              class="mr-3"
            ></v-avatar>
          </template>
          <template v-slot:[`item.data_tel`]="{ item }">
            {{ item.data_tel.replace(",", "") }}
          </template>
          <template v-slot:[`item.nota`]="{ item }">
            <td class="truncate">{{ item.nota }}</td>
          </template>
          <template v-slot:[`item.foto`]="{ item }">
            <v-icon class="mr-2" v-if="item.foto == 1"> mdi-camera </v-icon>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="!$vuetify.breakpoint.xs"
            >
              Esporta
              <v-icon right> mdi-download </v-icon>
            </v-btn>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="$vuetify.breakpoint.xs"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template></v-data-table
        >

        <!-- DIALOG IMMAGINI -->
        <v-dialog width="80%" v-model="dialog" persistent v-show="!checkObject">
          <v-card>
            <v-toolbar color="primary" dark
              >Nota numero {{ dettaglio.Id }}</v-toolbar
            >
            <v-card-text v-if="dettaglio != {}">
              <div class="text-h6 py-3">
                Inviata da {{ dettaglio.nome_cognome }} il
                {{ dettaglio.data }} alle
                {{ dettaglio.ora }}
              </div>
              <div class="body-1" style="white-space: pre-line;">Nota: {{ dettaglio.nota }}</div>
              <div class="mt-6 d-flex flex-wrap align-center" v-if="dettaglio.photos.length != 0">
                <img
                  v-for="(photo, i) in dettaglio.photos"
                  :key="i"
                  :src="photo"
                  width="250px"
                  class="pa-2"
                />
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="changeStatus()">Chiudi</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- EXPORT DIALOG -->
        <v-dialog v-model="dialogCSV" persistent max-width="500px">
          <v-card>
            <v-card-title
              :class="$vuetify.breakpoint.xs ? 'text-h6 pt-4' : 'headline pt-4'"
              >Download CSV</v-card-title
            >
            <v-card-text>
              <div class="mt-4">
                <p style="display: inline">File .csv pronto!</p>
                <a
                  :download="'Note-' + dateNow + '.csv'"
                  :href="fileCSV"
                  style="text-decoration: none; display: inline"
                  class="ml-4"
                >
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="$vuetify.breakpoint.xs"
                  >
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="!$vuetify.breakpoint.xs"
                  >
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogCSV = false"
                :loading="buttonLoading"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var dayjs = require("dayjs");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "Note",
  data() {
    return {
      note: [],
      headers: [
        {
          text: "",
          align: "start",
          value: "letta",
          width: "5%",
        },
        {
          text: "No.",
          value: "Id",
          width: "10%",
        },
        {
          text: "Nome",
          value: "nome_cognome",
          width: "20%",
        },
        {
          text: "Data",
          value: "data_tel",
          width: "20%",
          sort: (a, b) => {
            if (a == null || a == undefined || a == "") {
              a = "01/01/1970 00:00";
            }
            if (b == null || b == undefined || b == "") {
              b = "01/01/1970 00:00";
            }
            return (
              new Date(
                dayjs(a, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")
              ) -
              new Date(dayjs(b, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm"))
            );
          },
        },
        {
          text: "Nota",
          value: "nota",
        },
        {
          text: "Foto",
          value: "foto",
          width: "10%",
        },
      ],
      itemsPerPage: 15,
      search: "",
      dettaglio: {
        Id: "",
        nome_cognome: "",
        data: "",
        ora: "",
        photos: [],
        letta: null,
      },
      currentItems: [],
      dialog: false,
      dialogCSV: false,
      fileCSV: null,
      dateNow: "",
      filterDialog: false,
      buttonLoading: false,
    };
  },
  mounted() {
    this.$store.state.loading = true;
    this.getNote();
  },
  computed: {
    checkObject() {
      const isEmpty = Object.values(this.dettaglio).every(
        (x) =>
          x === null ||
          x === "" ||
          x.length === 0 ||
          (x[0] === null && x[1] === null)
      );

      if (isEmpty) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getNote() {
      this.axios
        .get(`${this.$store.state.url}note`, {
          params: {
            id_utente: localStorage.getItem("user"),
          },
        })
        .then((response) => {
          this.note = response.data.note;
          this.note.map((el) => {
            el["nome_cognome"] = `${el.nome} ${el.cognome}`;
          });
          this.$store.state.loading = false;
          this.search = this.$store.state.searchNote;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    getColor(item) {
      if (item.letta == 1) {
        return "green";
      } else {
        return "red";
      }
    },
    openImage(nota) {
      this.dettaglio = Object.assign({}, nota);
      this.dettaglio.data = nota.data_tel.split(", ")[0];
      this.dettaglio.ora = nota.data_tel.split(", ")[1].substr(0, 5);
      this.dialog = true;
    },
    clearObject() {
      this.dettaglio = {
        Id: "",
        nome_cognome: "",
        data: "",
        ora: "",
        photos: [],
        letta: null,
      };
      this.dialog = false;
    },
    changeStatus() {
      if (this.dettaglio.letta == 0) {
        this.buttonLoading = true;
        this.axios
          .put(`${this.$store.state.url}note`, {
            id_utente: localStorage.getItem("user"),
            id_nota: this.dettaglio.Id,
          })
          .then(() => {
            this.buttonLoading = false;
            this.getNote();
            this.clearObject();
            this.$emit("get-notifiche");
          })
          .catch((error) => {
            this.buttonLoading = false;
            this.clearObject();
            if (error.response != undefined) {
              if (error.response.status == 401) {
                this.$root.$emit("logout", error.response.data.message);
              }
            }
          });
      } else {
        this.clearObject();
      }
    },
    getData(e) {
      this.currentItems = e;
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.$nextTick(() => {
        csv += `"Dipendente";"Nota";"Foto allegate";"Data"\n`;

        this.currentItems.forEach(function (row) {
          if (row["data_tel"] == undefined) {
            row["data_tel"] = "";
          }

          var foto;
          if (row["foto"] == 0) {
            foto = "No";
          } else {
            foto = "Si";
          }

          csv += `"${row["nome_cognome"]}";"${row["nota"]}";"${foto}";"${row["data_tel"]}";\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
        this.itemsPerPage = itemsBuffer;
      });
    },
  },
  watch: {
    search: function () {
      this.$store.state.searchNote = this.search;
    },
  },
};
</script>

<style>
#note
  > div
  > div
  > div.v-data-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light
  > div.v-data-table__wrapper
  > table
  > tbody
  > tr:hover {
  cursor: pointer;
}

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
</style>