var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:(_vm.$vuetify.breakpoint.xs
      ? 'height: calc(100vh - 64px); margin-top: 64px'
      : 'height: calc(100vh - 128px); margin-top: 128px'),attrs:{"id":"crea-intervento"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.tipologie,"item-text":"nome","item-value":"Id","label":"Tipo intervento","rules":[(v) => !!v || 'Campo obbligatorio']},on:{"change":function($event){return _vm.resetInput()}},model:{value:(_vm.nuovoIntervento.tipo),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "tipo", $$v)},expression:"nuovoIntervento.tipo"}})],1),(
                    _vm.nuovoIntervento.tipo != 7 && _vm.nuovoIntervento.tipo != 8
                  )?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.clienti,"item-text":"text","item-value":"id","label":"Cliente","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.nuovoIntervento.cliente),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "cliente", $$v)},expression:"nuovoIntervento.cliente"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Inizio intervento","prepend-icon":"mdi-calendar","readonly":"","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.nuovoIntervento.data_inizio),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "data_inizio", $$v)},expression:"nuovoIntervento.data_inizio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"min":_vm.nuovoIntervento.data_termine &&
                        _vm.nuovoIntervento.ricorrenze != 2 &&
                        _vm.nuovoIntervento.ricorrenze != 3
                          ? _vm.nuovoIntervento.data_termine
                          : _vm.currentDate,"max":_vm.nuovoIntervento.data_termine
                          ? _vm.nuovoIntervento.data_termine
                          : '',"locale":"it-IT","first-day-of-week":"1"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.nuovoIntervento.data_inizio),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "data_inizio", $$v)},expression:"nuovoIntervento.data_inizio"}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.nuovoIntervento.ora_inizio,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.nuovoIntervento, "ora_inizio", $event)},"update:return-value":function($event){return _vm.$set(_vm.nuovoIntervento, "ora_inizio", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ora inizio","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.nuovoIntervento.ora_inizio),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "ora_inizio", $$v)},expression:"nuovoIntervento.ora_inizio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":"","max":_vm.nuovoIntervento.ora_termine,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menu2.save(_vm.nuovoIntervento.ora_inizio)}},model:{value:(_vm.nuovoIntervento.ora_inizio),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "ora_inizio", $$v)},expression:"nuovoIntervento.ora_inizio"}}):_vm._e()],1)],1)],1),(_vm.nuovoIntervento.tipo != 7 && _vm.nuovoIntervento.tipo != 8)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.ricorrenze,"item-text":"text","item-value":"value","label":"Ricorrenze","rules":_vm.nuovoIntervento.tipo != 7 && _vm.nuovoIntervento.tipo != 8
                        ? [(v) => !!v || 'Campo obbligatorio']
                        : []},on:{"change":function($event){_vm.nuovoIntervento.giorni = []}},model:{value:(_vm.nuovoIntervento.ricorrenze),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "ricorrenze", $$v)},expression:"nuovoIntervento.ricorrenze"}})],1),(
                    _vm.nuovoIntervento.ricorrenze == 2 ||
                    _vm.nuovoIntervento.ricorrenze == 3
                  )?_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-card-text',[_c('span',{staticClass:"subheading"},[_vm._v("Si ripete il")]),_c('v-chip-group',{attrs:{"active-class":"deep-purple--text text--accent-4","mandatory":"","multiple":_vm.nuovoIntervento.ricorrenze == 3 ? true : false},model:{value:(_vm.nuovoIntervento.giorni),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "giorni", $$v)},expression:"nuovoIntervento.giorni"}},_vm._l((_vm.giorni),function(giorno,i){return _c('v-chip',{key:i,attrs:{"value":giorno.value}},[_vm._v(" "+_vm._s(giorno.text)+" ")])}),1)],1)],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fine intervento","prepend-icon":"mdi-calendar","readonly":"","clearable":true,"rules":!_vm.nuovoIntervento.ora_termine &&
                          _vm.nuovoIntervento.tipo != 7 &&
                          _vm.nuovoIntervento.tipo != 8 &&
                          _vm.nuovoIntervento.ricorrenze != 2 &&
                          _vm.nuovoIntervento.ricorrenze != 3
                            ? []
                            : [(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.nuovoIntervento.data_termine),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "data_termine", $$v)},expression:"nuovoIntervento.data_termine"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"min":_vm.nuovoIntervento.data_inizio,"max":_vm.nuovoIntervento.tipo != 7 &&
                        _vm.nuovoIntervento.tipo != 8 &&
                        _vm.nuovoIntervento.ricorrenze != 2 &&
                        _vm.nuovoIntervento.ricorrenze != 3
                          ? _vm.nuovoIntervento.data_inizio
                          : '',"locale":"it-IT","first-day-of-week":"1"},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.nuovoIntervento.data_termine),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "data_termine", $$v)},expression:"nuovoIntervento.data_termine"}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-menu',{ref:"menu4",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.nuovoIntervento.ora_termine,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.nuovoIntervento, "ora_termine", $event)},"update:return-value":function($event){return _vm.$set(_vm.nuovoIntervento, "ora_termine", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ora fine","prepend-icon":"mdi-clock-time-four-outline","readonly":"","clearable":true,"rules":!_vm.nuovoIntervento.data_termine
                            ? []
                            : [(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.nuovoIntervento.ora_termine),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "ora_termine", $$v)},expression:"nuovoIntervento.ora_termine"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[(_vm.menu4)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","min":_vm.nuovoIntervento.data_inizio ==
                          _vm.nuovoIntervento.data_termine ||
                        _vm.nuovoIntervento.ricorrenze == 2 ||
                        _vm.nuovoIntervento.ricorrenze == 3 ||
                        _vm.nuovoIntervento.ora_inizio
                          ? _vm.nuovoIntervento.ora_inizio
                          : ''},on:{"click:minute":function($event){return _vm.$refs.menu4.save(_vm.nuovoIntervento.ora_termine)}},model:{value:(_vm.nuovoIntervento.ora_termine),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "ora_termine", $$v)},expression:"nuovoIntervento.ora_termine"}}):_vm._e()],1)],1)],1),_vm._l((_vm.nuovoIntervento.dipendenti),function(utente,i){return _c('v-row',{key:i},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.utentiNomi,"item-text":"nome","item-value":"id","label":"Dipendente","rules":_vm.nuovoIntervento.tipo != 7 && _vm.nuovoIntervento.tipo != 8
                        ? [
                            _vm.checkSelection(
                              _vm.nuovoIntervento.dipendenti[i].id_utente,
                              i
                            ) || 'Dipendete è stato selezionato più volte',
                          ]
                        : [(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.nuovoIntervento.dipendenti[i].id_utente),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento.dipendenti[i], "id_utente", $$v)},expression:"nuovoIntervento.dipendenti[i].id_utente"}})],1),(
                    _vm.nuovoIntervento.tipo != 7 && _vm.nuovoIntervento.tipo != 8
                  )?_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.categorie,"item-text":"categoria","item-value":"Id","label":"Categoria","disabled":!_vm.nuovoIntervento.dipendenti[i].id_utente,"rules":!_vm.nuovoIntervento.dipendenti[i].id_utente
                        ? []
                        : [
                            (v) =>
                              !!v || 'Seleziona una categoria per dipendente',
                          ]},model:{value:(_vm.nuovoIntervento.dipendenti[i].id_categoria),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento.dipendenti[i], "id_categoria", $$v)},expression:"nuovoIntervento.dipendenti[i].id_categoria"}})],1):_vm._e(),(
                    _vm.nuovoIntervento.tipo != 7 && _vm.nuovoIntervento.tipo != 8
                  )?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","sm":"3"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"x-small":"","outlined":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.removeUtente(i)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1)}),(_vm.nuovoIntervento.tipo != 7 && _vm.nuovoIntervento.tipo != 8)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.aggiungiUtente()}}},[_vm._v(" Aggiungi dipendente ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-textarea',{attrs:{"clearable":true,"outlined":"","clear-icon":"mdi-close-circle","label":"Note","maxlength":"255","counter":"255"},model:{value:(_vm.nuovoIntervento.note),callback:function ($$v) {_vm.$set(_vm.nuovoIntervento, "note", $$v)},expression:"nuovoIntervento.note"}})],1)],1)],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" Cancella tutto ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.valid,"loading":_vm.buttonLoading},on:{"click":function($event){return _vm.saveIntervento()}}},[_vm._v(" Salva ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"outlined":"","type":"error","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.dateError)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }