<template>
  <v-container style="height: 100vh">
    <div class="error-container">
      <div class="text-h1 color mb-5">¯\_(ツ)_/¯</div>
      <h1>Pagina non trovata</h1>
      <v-btn x-large color="primary" dark to="/home">
        Torna alla homepage
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "PageError",
};
</script>

<style scoped>
.error-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.color {
  color: #151b42;
}
</style>