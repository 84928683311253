<template>
  <v-container
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
    id="dettaglio"
  >
    <v-row v-if="intervento.length != 0" class="justify-center">
      <v-col cols="12" sm="12" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          outlined
          elevation="2"
          height="100%"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">INTERVENTO</div>
              <v-list-item-title class="text-h6 mb-1">
                {{ intervento[0].nome_tipo }}
              </v-list-item-title>
              <v-list-item-subtitle
                >Inizio:
                <b>{{ intervento[0].data_ora_inizio }}</b>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="intervento[0].data_ora_termine != null"
                >Termine:
                <b>{{ intervento[0].data_ora_termine }}</b>
              </v-list-item-subtitle>
              <v-card-text
                v-if="intervento[0].note_admin != null"
                class="pa-0 grey-text"
                >Note:
                {{ intervento[0].note_admin }}
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          outlined
          elevation="2"
          height="100%"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">CLIENTE</div>
              <v-list-item-title class="text-h6 mb-1">
                {{ intervento[0].rag_soc }}
              </v-list-item-title>
              <v-list-item-subtitle
                >Indirizzo:
                <b>{{ intervento[0].indirizzo }}</b>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                >Localita:
                <b
                  >{{ intervento[0].CAP == null ? "" : intervento[0].CAP }}
                  {{ intervento[0].localita }}
                  {{
                    intervento[0].provincia == null ||
                    intervento[0].provincia == ""
                      ? ""
                      : `(${intervento[0].provincia})`
                  }}</b
                >
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="intervento[0].telefono != null"
                >Telefono:
                <b>{{ intervento[0].telefono }}</b>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="intervento[0].email != null"
                >Email:
                <b>{{ intervento[0].email }}</b>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card-title>Intervento svolto da:</v-card-title>
      </v-col>
    </v-row>
    <v-row v-for="(dipendente, i) in intervento" :key="i">
      <v-col cols="12" sm="12" md="6">
        <v-card class="mx-auto" outlined elevation="2">
          <v-list-item three-line>
            <v-list-item-avatar tile size="50"
              ><v-icon large>mdi-account</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <div class="text-overline">
                {{ dipendente.nome_cognome }}
              </div>
              <v-list-item-subtitle>{{
                dipendente.categoria
              }}</v-list-item-subtitle>
              <v-card-text class="px-0" v-if="dipendente.note_utente != null">
                <b>Note: </b>
                {{ dipendente.note_utente }}
              </v-card-text>
              <div
                class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex'"
                v-if="dipendente.gestito > 0"
              >
                <div class="text-center mr-10">
                  <v-card-text
                    class="mt-2 pa-0 text-start d-flex align-center"
                    v-if="dipendente.inizio_tel"
                    ><b>Inizio: </b>
                    {{
                      dipendente.inizio_tel != null
                        ? dipendente.inizio_tel.substring(0, 17)
                        : ""
                    }}

                    <v-icon
                      class="justify-start ml-2"
                      @click="
                        openMaps(dipendente.lat_inizio, dipendente.long_inizio)
                      "
                      v-if="
                        dipendente.lat_inizio != null &&
                        dipendente.long_inizio != null &&
                        parseInt(dipendente.long_termine) != 0 &&
                        parseInt(dipendente.lat_termine) != 0
                      "
                      >mdi-map-marker
                    </v-icon>
                  </v-card-text>
                  <div v-if="dipendente.photo_inizio != ''">
                    <v-img
                      :src="dipendente.photo_inizio"
                      @click="openImage(dipendente.photo_inizio)"
                      width="100px"
                    ></v-img>
                  </div>
                </div>

                <div
                  class="text-center"
                  v-if="dipendente.gestito == 2 && dipendente.termine_tel"
                >
                  <v-card-text class="mt-2 pa-0 text-start d-flex align-center"
                    ><b>Fine: </b>
                    {{
                      dipendente.termine_tel != null
                        ? dipendente.termine_tel.substring(0, 17)
                        : ""
                    }}

                    <v-icon
                      class="justify-start ml-2"
                      @click="
                        openMaps(
                          dipendente.lat_termine,
                          dipendente.long_termine
                        )
                      "
                      v-if="
                        dipendente.lat_termine != null &&
                        dipendente.long_termine != null &&
                        parseInt(dipendente.long_termine) != 0 &&
                        parseInt(dipendente.lat_termine) != 0
                      "
                      >mdi-map-marker
                    </v-icon>
                  </v-card-text>
                  <div v-if="dipendente.photo_termine != ''">
                    <v-img
                      :src="dipendente.photo_termine"
                      @click="openImage(dipendente.photo_termine)"
                      width="100px"
                    ></v-img>
                  </div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-text class="pa-4">
          <v-img :src="selectedImage"></v-img
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "DettaglioIntervento",
  data: () => ({
    id: null,
    intervento: [],
    dialog: false,
    selectedImage: "",
  }),
  mounted() {
    this.$store.state.loading = true;
    this.id = this.$route.params.id;
    this.getIntervento();
  },
  methods: {
    getIntervento() {
      this.axios
        .get(`${this.$store.state.url}interventi`, {
          params: {
            id_utente: localStorage.getItem("user"),
            livello: "1",
            id_intervento: this.id,
          },
        })
        .then((response) => {
          this.intervento = response.data.interventi;
          this.intervento.map((el) => {
            el["nome_cognome"] = `${el.nome_dip} ${el.cognome}`;

            if (el.data_inizio == null) {
              el.data_inizio = "";
            }
            if (el.ora_inizio == null) {
              el.ora_inizio = "";
            }
            if (el.data_termine == null) {
              el.data_termine = "";
            }
            if (el.ora_termine == null) {
              el.ora_termine = "";
            }
            if (el.note_admin == null) {
              el.note_admin = "";
            }
            el["data_ora_inizio"] = `${el.data_inizio} ${el.ora_inizio.slice(
              0,
              -3
            )}`;
            el["data_ora_termine"] = `${el.data_termine} ${el.ora_termine.slice(
              0,
              -3
            )}`;
          });
          this.$store.state.loading = false;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    openMaps(lat, long) {
      var url = `https://maps.google.com/?q=${lat},${long}`;
      window.open(url, "_blank");
    },
    openImage(photo) {
      this.selectedImage = photo;
      this.dialog = true;
    },
  },
};
</script>

<style>
.grey-text {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>