<template>
  <v-container
    id="utenti"
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
    :class="utenti.length == 0 ? 'd-flex justify-center align-center' : ''"
  >
    <v-row>
      <v-col cols="12">
        <!-- NUOVO UTENTE -->
        <div class="text-center" v-if="utenti.length == 0">
          <h2 class="mb-2">Crea il primo dipendente</h2>

          <v-btn icon @click="dialog = true">
            <v-icon large>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>

        <!-- TABELLA -->
        <v-data-table
          v-if="utenti.length != 0"
          :headers="headers"
          :items="utenti"
          :items-per-page="itemsPerPage"
          :sort-by="'cognome'"
          :sort-desc="false"
          :search="search"
          :custom-filter="filterAll"
          multi-sort
          no-results-text="Nessun risultato"
          fixed-header
          :height="
            $vuetify.breakpoint.xs
              ? 'calc(100vh - 331px)'
              : 'calc(100vh - 339px)'
          "
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }"
          @current-items="getData"
        >
          <template v-slot:top>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                class="search"
                :clearable="true"
              ></v-text-field>

              <!-- FILTER -->
              <v-dialog v-model="filterDialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-show="!checkFilters"
                    ><v-icon @click="clearFilter()"
                      >mdi-filter-remove-outline</v-icon
                    ></v-btn
                  >
                  <v-btn icon
                    ><v-icon v-bind="attrs" v-on="on"
                      >mdi-filter-outline</v-icon
                    ></v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Filtra per:</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.nome"
                            label="Nome e cognome"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.indirizzo"
                            label="Indirizzo"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.CAP"
                            label="CAP"
                            maxlength="5"
                            counter="5"
                            @keypress="isInteger($event)"
                            :rules="[
                              (v) =>
                                /^(\s*|\d+)$/.test(v) || 'Inserire solo numeri',
                            ]"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="locList"
                            item-text="text"
                            item-value="value"
                            label="Località"
                            v-model="filtersObj.loc"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                          <v-select
                            :items="provList"
                            item-text="text"
                            item-value="value"
                            label="Provincia"
                            v-model="filtersObj.prov"
                            multiple
                          ></v-select>
                        </v-col> -->
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.tel"
                            label="Telefono"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="filtersObj.email"
                            label="Email"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                          <v-select
                            :items="livList"
                            item-text="text"
                            item-value="value"
                            label="Livello"
                            v-model="filtersObj.livello"
                          ></v-select>
                        </v-col> -->
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="clienti"
                            item-text="rag_soc"
                            item-value="id"
                            label="Clienti"
                            v-model="filtersObj.clienti"
                            multiple
                            small-chips
                            deletable-chips
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions
                    :class="$vuetify.breakpoint.xs ? 'flex-wrap' : ''"
                  >
                    <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
                    <v-btn color="blue darken-1" text @click="clearFilter()">
                      Cancella tutto
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="filterDialog = false"
                    >
                      Chiudi
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="filterDialog = false"
                      :class="$vuetify.breakpoint.xs ? 'ml-0' : ''"
                    >
                      Conferma
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-card-title>
          </template>

          <!-- COLONNE DELLA TABELLA -->
          <template v-slot:[`item.livello`]="{ item }">
            {{ getLevel(item.livello) }}
          </template>
          <template v-slot:[`item.clienti`]="{ item }">
            {{ getClient(item.clienti) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              color="primary"
              dark
              v-if="item.stato == 'd'"
              @click="riattivaUtente(item)"
            >
              Riattiva
            </v-btn>
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              class="mr-2"
              @click="modificaUtente(item)"
              v-if="item.stato != 'd'"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              :small="!$vuetify.breakpoint.xs"
              @click="cancellaUtente(item)"
              v-if="item.stato != 'd'"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="!$vuetify.breakpoint.xs"
            >
              Esporta
              <v-icon right> mdi-download </v-icon>
            </v-btn>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 px-2"
              color="grey darken-3"
              v-if="$vuetify.breakpoint.xs"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template></v-data-table
        >

        <!-- DIALOG AGGIUNGI/MODIFICA -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.nome"
                        label="Nome"
                        required
                        maxlength="30"
                        counter="30"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.cognome"
                        label="Cognome"
                        required
                        maxlength="30"
                        counter="30"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.username"
                        label="Username"
                        readonly
                        required
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="editedIndex == -1">
                      <v-text-field
                        v-model="editedUser.username"
                        label="Password provvisoria"
                        readonly
                        required
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.indirizzo"
                        label="Indirizzo"
                        placeholder="Via dell'Artigianato 19"
                        maxlength="50"
                        counter="50"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.CAP"
                        label="CAP"
                        placeholder="20835"
                        maxlength="5"
                        counter="5"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            /^(\s*|\d+)$/.test(v) || 'Inserire solo numeri',
                          (v) => !v || v.length == 5 || 'Valore non valido',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.localita"
                        label="Località"
                        placeholder="Muggiò"
                        maxlength="30"
                        counter="30"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.provincia"
                        label="Provincia"
                        placeholder="MB"
                        maxlength="2"
                        counter="2"
                        :rules="[
                          (v) =>
                            !v ||
                            /^[A-Z]+$/.test(v) ||
                            'Inserire solo lettere maiuscole',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.telefono"
                        label="Telefono"
                        placeholder="1234567890"
                        maxlength="10"
                        counter="10"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            !v ||
                            /^(\s*|\d+)$/.test(v) ||
                            'Inserire solo numeri',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.email"
                        label="Email"
                        placeholder="info@newfaemac.it"
                        maxlength="50"
                        counter="50"
                        required
                        :rules="[
                          (v) => !!v || 'Campo obbligatorio',
                          (v) => /.+@.+\..+/.test(v) || 'E-mail non valida',
                          (v) => checkEmail() || 'Email già in utilizzo',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="editLevel"
                        item-text="text"
                        item-value="value"
                        label="Livello"
                        v-model="editedUser.livello"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                        small-chips
                        deletable-chips
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="clienti"
                        item-text="text"
                        item-value="id"
                        label="Clienti"
                        v-model="editedUser.clienti"
                        multiple
                        small-chips
                        deletable-chips
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">
                Chiudi
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!valid"
                :loading="buttonLoading"
                @click="saveUtente()"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- DELETE DIALOG -->
        <v-dialog v-model="dialogDelete" max-width="550px" persistent>
          <v-card>
            <v-card-title
              style="word-break: break-word"
              :class="
                $vuetify.breakpoint.xs
                  ? 'subtitle-1 justify-center text-center delete'
                  : 'text-h6 justify-center text-center delete'
              "
              >Sei sicuro di voler disattivare il dipendente "{{
                editedUser.nome
              }}
              {{ editedUser.cognome }}"?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete()"
                >Chiudi</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="cancellaUtenteConferma()"
                :loading="buttonLoading"
                >Conferma</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- EXPORT DIALOG -->
        <v-dialog v-model="dialogCSV" max-width="500px">
          <v-card>
            <v-card-title
              :class="$vuetify.breakpoint.xs ? 'text-h6 pt-4' : 'headline pt-4'"
              >Download CSV</v-card-title
            >
            <v-card-text>
              <div class="mt-4">
                <p style="display: inline">File .csv pronto!</p>
                <a
                  :download="'Dipendenti-' + dateNow + '.csv'"
                  :href="fileCSV"
                  style="text-decoration: none; display: inline"
                  class="ml-4"
                >
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="$vuetify.breakpoint.xs"
                  >
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                  <v-btn
                    @click="dialogCSV = false"
                    color="primary"
                    v-if="!$vuetify.breakpoint.xs"
                  >
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogCSV = false"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Dipendenti",
  data() {
    return {
      utenti: [],
      clienti: [],
      itemsPerPage: 15,
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "nome_cognome",
          filter: (value) => {
            if (!this.filtersObj.nome) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.nome.toLowerCase());
            }
          },
        },
        {
          text: "Indirizzo",
          value: "indirizzo",
          filter: (value) => {
            if (!this.filtersObj.indirizzo) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.indirizzo.toLowerCase());
            }
          },
        },
        // {
        //   text: "CAP",
        //   value: "CAP",
        //   width: "5%",
        //   filter: (value) => {
        //     if (!this.filtersObj.CAP) return true;
        //     if (value != null) {
        //       return value
        //         .toLowerCase()
        //         .includes(this.filtersObj.CAP.toLowerCase());
        //     }
        //   },
        // },
        {
          text: "Località",
          value: "localita",
          filter: (value) => {
            if (this.filtersObj.loc.length == 0) return true;
            return this.filtersObj.loc.includes(value);
          },
        },
        // {
        //   text: "Provincia",
        //   value: "provincia",

        //   filter: (value) => {
        //     if (this.filtersObj.prov.length == 0) return true;
        //     return this.filtersObj.prov.includes(value);
        //   },
        // },
        {
          text: "Telefono",
          value: "telefono",
          filter: (value) => {
            if (!this.filtersObj.tel) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.tel.toLowerCase());
            }
          },
        },
        {
          text: "Email",
          value: "email",
          filter: (value) => {
            if (!this.filtersObj.email) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.email.toLowerCase());
            }
          },
        },
        // {
        //   text: "Livello",
        //   value: "livello",
        //   filter: (value) => {
        //     if (!this.filtersObj.livello || this.filtersObj.livello == 0)
        //       return true;
        //     return this.filtersObj.livello == value;
        //   },
        // },
        {
          text: "Clienti",
          value: "clienti",
          filter: (value) => {
            if (this.filtersObj.clienti.length == 0) return true;
            var res = [];
            for (let i = 0; i < this.filtersObj.clienti.length; i++) {
              res.push(value.includes(this.filtersObj.clienti[i]));
            }
            return res.includes(true);
          },
        },
        { text: "Azioni", value: "actions", sortable: false },
      ],
      dialog: false,
      provList: [],
      locList: [],
      livList: [
        { text: "Tutti", value: 0 },
        { text: "Admin", value: 1 },
        { text: "Dipendente", value: 2 },
      ],
      editLevel: [
        { text: "Admin", value: 1 },
        { text: "Dipendente", value: 2 },
      ],
      buttonLoading: false,
      searchUtenti: "",
      filterDialog: false,
      filtersObj: {
        nome: "",
        indirizzo: "",
        // CAP: "",
        loc: [],
        // prov: [],
        tel: "",
        email: "",
        clienti: [],
      },
      search: "",
      editedIndex: -1,
      emailUser: "",
      username: "",
      defaultUser: {
        id: "",
        nome: "",
        cognome: "",
        username: "",
        indirizzo: "",
        CAP: "",
        loc: "",
        prov: "",
        tel: "",
        email: "",
        clienti: [],
        livello: 0,
      },
      editedUser: {
        id: "",
        nome: "",
        cognome: "",
        username: "",
        indirizzo: "",
        CAP: "",
        loc: "",
        prov: "",
        tel: "",
        email: "",
        clienti: [],
        livello: 0,
      },
      valid: false,
      dialogDelete: false,
      dialogCSV: false,
      currentItems: [],
      fileCSV: null,
      dateNow: "",
    };
  },
  mounted() {
    this.$store.state.loading = true;
    this.getUtenti();
  },
  computed: {
    checkFilters() {
      const isEmpty = Object.values(this.filtersObj).every(
        (x) =>
          x === null ||
          x === "" ||
          x.length === 0 ||
          (x[0] === null && x[1] === null)
      );

      if (isEmpty) {
        return true;
      } else {
        return false;
      }
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Nuovo dipendente"
        : "Modifica dipendente";
    },
  },
  methods: {
    getUtenti() {
      this.axios
        .get(`${this.$store.state.url}utenti`, {
          params: {
            id_utente: localStorage.getItem("user"),
            nuovo_int: false,
          },
        })
        .then((response) => {
          this.utenti = response.data.utenti;
          this.clienti = response.data.clienti;
          this.utenti.map((el) => {
            el["nome_cognome"] = `${el.nome} ${el.cognome}`;
          });

          this.utenti.forEach((el) => {
            if (
              !this.locList.includes(el["localita"]) &&
              el["localita"] != ""
            ) {
              this.locList.push(el["localita"]);
            }
            if (
              !this.provList.includes(el["provincia"]) &&
              el["provincia"] != ""
            ) {
              this.provList.push(el["provincia"]);
            }
          });

          this.$store.state.loading = false;
          this.search = this.$store.state.searchUtenti;
          this.filtersObj = this.$store.state.filtersUtenti;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    getLevel(level) {
      if (level == 1) {
        return "Admin";
      } else {
        return "Dipendente";
      }
    },
    getClient(client) {
      if (client != undefined) {
        var result = [];
        for (let i = 0; i < client.length; i++) {
          result.push(
            this.clienti.filter((e) => e.id == client[i]).map((e) => e.rag_soc)
          );
        }
        return result.join(", ");
      }
    },
    filterAll(valore, search, item) {
      var results = [];
      var columns = [];

      this.headers.forEach((element) => {
        columns.push(element.value);
      });

      Object.entries(item).forEach(([key, value]) => {
        // if (key == "livello") {
        //   value = this.getLevel(value);
        // }

        if (key == "clienti") {
          value = this.getClient(value);
        }

        if (
          value != undefined &&
          value != null &&
          value != "" &&
          columns.includes(key) &&
          search != null &&
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
        ) {
          results.push("true");
        }
      });
      return results.includes("true");
    },
    clearFilter() {
      this.filtersObj = {
        nome: "",
        indirizzo: "",
        // CAP: "",
        loc: [],
        // prov: [],
        tel: "",
        email: "",
        clienti: [],
      };
    },
    modificaUtente(item) {
      this.editedIndex = this.utenti.indexOf(item);
      this.emailUser = item["email"];
      this.editedUser = Object.assign({}, item);
      // this.acceptNumber();
      this.dialog = true;
    },
    acceptNumber() {
      if (
        this.editedUser["telefono"] != "" &&
        this.editedUser["telefono"] != null &&
        this.editedUser["telefono"] != undefined
      ) {
        var x = this.editedUser["telefono"]
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.editedUser["telefono"] = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkEmail() {
      if (
        this.editedUser["email"] != "" &&
        this.editedUser["email"] != null &&
        this.editedUser["email"] != undefined
      ) {
        var check = true;
        this.utenti.forEach((el) => {
          if (
            el.id != this.editedUser.id &&
            el.email == this.editedUser.email
          ) {
            check = false;
          }
        });
        return check;
      }
      return true;
    },
    close() {
      this.dialog = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
        this.emailUser = "";
      });
    },
    saveUtente() {
      this.buttonLoading = true;
      this.axios
        .post(`${this.$store.state.url}utenti`, {
          id_utente: localStorage.getItem("user"),
          modifica_id: this.editedUser["id"],
          nome: this.editedUser["nome"],
          cognome: this.editedUser["cognome"],
          username: this.editedUser["username"],
          password: this.editedUser["username"],
          indirizzo:
            this.editedUser["indirizzo"] == undefined
              ? ""
              : this.editedUser["indirizzo"],
          CAP:
            this.editedUser["CAP"] == undefined ? "" : this.editedUser["CAP"],
          loc:
            this.editedUser["localita"] == undefined
              ? ""
              : this.editedUser["localita"],
          prov:
            this.editedUser["provincia"] == undefined
              ? ""
              : this.editedUser["provincia"],
          tel:
            this.editedUser["telefono"] == undefined
              ? ""
              : this.editedUser["telefono"],
          old_email: this.emailUser,
          new_email: this.editedUser["email"],
          livello: this.editedUser["livello"],
          clienti: this.editedUser["clienti"],
        })
        .then(() => {
          this.buttonLoading = false;
          this.dialog = false;
          this.getUtenti();
          if (this.emailUser == "") {
            this.$root.Snackbar.show({
              text: `Il dipendente "${this.editedUser["nome"]} ${this.editedUser["cognome"]}" è stato creato`,
            });
          } else {
            this.$root.Snackbar.show({
              text: `Il dipendente "${this.editedUser["nome"]} ${this.editedUser["cognome"]}" è stato modificato`,
            });
          }
          this.close();
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.dialog = false;
          this.close();
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    riattivaUtente(user) {
      console.log(user);
      this.buttonLoading = true;
      this.axios
        .post(`${this.$store.state.url}riattiva`, {
          id_utente: localStorage.getItem("user"),
          utente: user.id,
          email: user.email,
        })
        .then(() => {
          this.buttonLoading = false;
          this.getUtenti();
          this.$root.Snackbar.show({
            text: `Il dipendente "${user.nome} ${user.cognome}" è stato riattivato`,
          });
        })
        .catch((error) => {
          this.buttonLoading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    cancellaUtente(item) {
      this.editedIndex = this.utenti.indexOf(item);
      this.emailUser = item["email"];
      this.editedUser = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
        this.emailUser = "";
      });
    },
    cancellaUtenteConferma() {
      this.buttonLoading = true;
      this.axios
        .delete(`${this.$store.state.url}utenti`, {
          params: {
            id_utente: localStorage.getItem("user"),
            utente: this.editedUser["id"],
            email: this.editedUser["email"],
          },
        })
        .then(() => {
          this.buttonLoading = false;
          this.dialogDelete = false;
          this.getUtenti();
          this.$root.Snackbar.show({
            text: `Il dipendente "${this.editedUser["nome"]} ${this.editedUser["cognome"]}" è stato disattivato`,
          });
          this.closeDelete();
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.dialogDelete = false;
          this.closeDelete();
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    getData(e) {
      this.currentItems = e;
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;
      this.currentItems.forEach((row) => {
        row["clienti1"] = this.getClient(row["clienti"]);
      });
      this.$nextTick(() => {
        csv += `"Dipendente";"Indirizzo";"Località";"Telefono";"Email";"Clienti"\n`;
        this.currentItems.forEach(function (row) {
          csv += `"${row["nome"]} ${row["cognome"]}";"${
            row["indirizzo"] == undefined ? "" : row["indirizzo"]
          }";"${row["localita"] == undefined ? "" : row["localita"]}";"${
            row["telefono"] == undefined ? "" : row["telefono"]
          }";"${row["email"] == undefined ? "" : row["email"]}";"${
            row["clienti1"]
          }";\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
        this.itemsPerPage = itemsBuffer;
      });
    },
  },
  watch: {
    search: function () {
      this.$store.state.searchUtenti = this.search;
    },
    filtersObj: function () {
      this.$store.state.filtersUtenti = this.filtersObj;
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    editedUser: {
      deep: true,
      handler(val) {
        if (val["nome"] != undefined && val["cognome"] != undefined) {
          var nome = val["nome"].trimStart().split(" ")[0];
          var cognome = val["cognome"].trimEnd().split(" ");
          cognome = cognome[cognome.length - 1];
          this.editedUser["username"] = `${nome
            .charAt(0)
            .toLowerCase()}${cognome.toLowerCase()}`;
        }
      },
    },
  },
};
</script>

<style>
#utenti .search {
  padding: 0;
  margin: 0;
}
</style>