<template>
  <v-snackbar
    :timeout="timeout"
    v-model="snackbar"
    bottom
    tile
    color="red accent-2"
    width="100vw"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      snackbar: false,
      text: "",
      timeout: 3000,
    };
  },
  methods: {
    show(data) {
      this.text = data.text;
      this.timeout = data.timeout || 3000;
      this.snackbar = true;
    },
  },
};
</script>

<style>
.v-snack__content {
    text-align: center !important;
}
</style>