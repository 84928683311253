var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-col',{attrs:{"cols":"12","id":"form"}},[_c('v-card',[_c('v-card-title',[(
            _vm.updateAll != null ||
            (_vm.editedIntervento.ricorrenza != 2 &&
              _vm.editedIntervento.ricorrenza != 3)
          )?_c('span',{staticClass:"text-h5"},[_vm._v("Modifica intervento")]):_vm._e()]),_c('v-card-text',[(
            _vm.editedIntervento.ricorrenza == 2 ||
            _vm.editedIntervento.ricorrenza == 3
          )?_c('div',{class:_vm.updateAll == null && !_vm.intGestiti
              ? 'd-flex justify-space-between align-center flex-wrap'
              : 'd-flex align-center flex-wrap'},[_c('span',{staticClass:"text-h6"},[_vm._v("Intervento ricorrente: ")]),(_vm.updateAll == null)?_c('v-btn',{class:_vm.updateAll == null && !_vm.intGestiti ? '' : 'ml-2',attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.tipoModifica(false)}}},[_vm._v(" Modifica singolo intervento ")]):_vm._e(),(_vm.updateAll == null && !_vm.intGestiti)?_c('v-btn',{class:_vm.$vuetify.breakpoint.xs ? 'mt-3' : '',attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.tipoModifica(true)}}},[_vm._v(" Modifica tutti gli interventi ")]):_vm._e()],1):_vm._e(),(
            _vm.updateAll != null ||
            (_vm.editedIntervento.ricorrenza != 2 &&
              _vm.editedIntervento.ricorrenza != 3)
          )?_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.tipologie,"item-text":"nome","item-value":"Id","item-disabled":_vm.checkType(),"label":"Tipo intervento","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.editedIntervento.id_tipo_int),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "id_tipo_int", $$v)},expression:"editedIntervento.id_tipo_int"}})],1),(
                  _vm.editedIntervento.id_tipo_int != 7 &&
                  _vm.editedIntervento.id_tipo_int != 8
                )?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.clienti,"item-text":"text","item-value":"id","label":"Cliente","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.editedIntervento.id_cliente),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "id_cliente", $$v)},expression:"editedIntervento.id_cliente"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Inizio intervento","prepend-icon":"mdi-calendar","readonly":"","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.editedIntervento.data_inizio),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "data_inizio", $$v)},expression:"editedIntervento.data_inizio"}},'v-text-field',attrs,false),on))]}}],null,false,352665954),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"min":_vm.editedIntervento.data_termine && !_vm.updateAll
                        ? _vm.editedIntervento.data_termine
                        : _vm.currentDate,"max":_vm.editedIntervento.data_termine
                        ? _vm.editedIntervento.data_termine
                        : '',"locale":"it-IT","first-day-of-week":"1"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.editedIntervento.data_inizio),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "data_inizio", $$v)},expression:"editedIntervento.data_inizio"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.editedIntervento.ora_inizio,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedIntervento, "ora_inizio", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedIntervento, "ora_inizio", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ora inizio","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.editedIntervento.ora_inizio),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "ora_inizio", $$v)},expression:"editedIntervento.ora_inizio"}},'v-text-field',attrs,false),on))]}}],null,false,115696828),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":"","max":_vm.editedIntervento.ora_termine,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menu2.save(_vm.editedIntervento.ora_inizio)}},model:{value:(_vm.editedIntervento.ora_inizio),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "ora_inizio", $$v)},expression:"editedIntervento.ora_inizio"}}):_vm._e()],1)],1)],1),(
                _vm.editedIntervento.id_tipo_int != 7 &&
                _vm.editedIntervento.id_tipo_int != 8 &&
                _vm.updateAll
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.ricorrenze,"item-text":"text","item-value":"value","label":"Ricorrenze","item-disabled":_vm.checkType(),"rules":_vm.editedIntervento.id_tipo_int != 7 &&
                    _vm.editedIntervento.id_tipo_int != 8
                      ? [(v) => !!v || 'Campo obbligatorio']
                      : []},on:{"change":function($event){_vm.editedIntervento.giorni = []}},model:{value:(_vm.editedIntervento.ricorrenza),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "ricorrenza", $$v)},expression:"editedIntervento.ricorrenza"}})],1),(
                  _vm.editedIntervento.ricorrenza == 2 ||
                  _vm.editedIntervento.ricorrenza == 3
                )?_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-card-text',[_c('span',{staticClass:"subheading"},[_vm._v("Si ripete il")]),_c('v-chip-group',{attrs:{"active-class":"deep-purple--text text--accent-4","mandatory":"","multiple":_vm.editedIntervento.ricorrenza == 3 ? true : false},model:{value:(_vm.editedIntervento.giorni),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "giorni", $$v)},expression:"editedIntervento.giorni"}},_vm._l((_vm.giorni),function(giorno,i){return _c('v-chip',{key:i,attrs:{"value":giorno.value}},[_vm._v(" "+_vm._s(giorno.text)+" ")])}),1)],1)],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fine intervento","prepend-icon":"mdi-calendar","clearable":true,"readonly":"","rules":!_vm.editedIntervento.ora_termine &&
                        _vm.editedIntervento.id_tipo_int != 7 &&
                        _vm.editedIntervento.id_tipo_int != 8 &&
                        (!_vm.updateAll ||
                          (_vm.editedIntervento.ricorrenza != 2 &&
                            _vm.editedIntervento.ricorrenza != 3 &&
                            _vm.updateAll))
                          ? []
                          : [(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.editedIntervento.data_termine),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "data_termine", $$v)},expression:"editedIntervento.data_termine"}},'v-text-field',attrs,false),on))]}}],null,false,1703837674),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"min":_vm.editedIntervento.data_inizio,"max":_vm.editedIntervento.id_tipo_int != 7 &&
                      _vm.editedIntervento.id_tipo_int != 8 &&
                      (!_vm.updateAll ||
                        (_vm.editedIntervento.ricorrenza != 2 &&
                          _vm.editedIntervento.ricorrenza != 3 &&
                          _vm.updateAll))
                        ? _vm.editedIntervento.data_inizio
                        : '',"locale":"it-IT","first-day-of-week":"1"},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.editedIntervento.data_termine),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "data_termine", $$v)},expression:"editedIntervento.data_termine"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"menu4",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.editedIntervento.ora_termine,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedIntervento, "ora_termine", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedIntervento, "ora_termine", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ora fine","prepend-icon":"mdi-clock-time-four-outline","readonly":"","clearable":true,"rules":!_vm.editedIntervento.data_termine
                          ? []
                          : [(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.editedIntervento.ora_termine),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "ora_termine", $$v)},expression:"editedIntervento.ora_termine"}},'v-text-field',attrs,false),on))]}}],null,false,3602162967),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[(_vm.menu4)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","min":_vm.editedIntervento.data_inizio ==
                        _vm.editedIntervento.data_termine ||
                      _vm.editedIntervento.ricorrenza == 2 ||
                      _vm.editedIntervento.ricorrenza == 3 ||
                      _vm.editedIntervento.ora_inizio
                        ? _vm.editedIntervento.ora_inizio
                        : ''},on:{"click:minute":function($event){return _vm.$refs.menu4.save(_vm.editedIntervento.ora_termine)}},model:{value:(_vm.editedIntervento.ora_termine),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "ora_termine", $$v)},expression:"editedIntervento.ora_termine"}}):_vm._e()],1)],1)],1),_vm._l((_vm.editedIntervento.dipendenti),function(utente,i){return _c('v-row',{key:i},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.utentiNomi,"item-text":"nome","item-value":"id","label":"Dipendente","rules":_vm.editedIntervento.id_tipo_int != 7 &&
                    _vm.editedIntervento.id_tipo_int != 8
                      ? [
                          _vm.checkSelection(
                            _vm.editedIntervento.dipendenti[i].id_utente,
                            i
                          ) || 'Dipendete è stato selezionato più volte',
                        ]
                      : [(v) => !!v || 'Campo obbligatorio']},on:{"input":function($event){_vm.editedIntervento = Object.assign({}, _vm.editedIntervento)}},model:{value:(_vm.editedIntervento.dipendenti[i].id_utente),callback:function ($$v) {_vm.$set(_vm.editedIntervento.dipendenti[i], "id_utente", $$v)},expression:"editedIntervento.dipendenti[i].id_utente"}})],1),(
                  _vm.editedIntervento.id_tipo_int != 7 &&
                  _vm.editedIntervento.id_tipo_int != 8
                )?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.categorie,"item-text":"categoria","item-value":"Id","label":"Categoria","disabled":!_vm.editedIntervento.dipendenti[i].id_utente,"rules":!_vm.editedIntervento.dipendenti[i].id_utente
                      ? []
                      : [
                          (v) =>
                            !!v || 'Seleziona una categoria per dipendente',
                        ]},model:{value:(_vm.editedIntervento.dipendenti[i].id_categoria),callback:function ($$v) {_vm.$set(_vm.editedIntervento.dipendenti[i], "id_categoria", $$v)},expression:"editedIntervento.dipendenti[i].id_categoria"}})],1):_vm._e(),(
                  _vm.editedIntervento.id_tipo_int != 7 &&
                  _vm.editedIntervento.id_tipo_int != 8
                )?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"x-small":"","outlined":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.removeUtente(i)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1)}),(
                _vm.editedIntervento.id_tipo_int != 7 &&
                _vm.editedIntervento.id_tipo_int != 8
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.aggiungiUtente()}}},[_vm._v(" Aggiungi dipendente ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"clearable":true,"outlined":"","clear-icon":"mdi-close-circle","label":"Note","maxlength":"255","counter":"255"},model:{value:(_vm.editedIntervento.note_admin),callback:function ($$v) {_vm.$set(_vm.editedIntervento, "note_admin", $$v)},expression:"editedIntervento.note_admin"}})],1)],1)],2)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Chiudi ")]),(
            _vm.updateAll != null ||
            (_vm.editedIntervento.ricorrenza != 2 &&
              _vm.editedIntervento.ricorrenza != 3)
          )?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.valid,"loading":_vm.buttonLoading},on:{"click":function($event){return _vm.saveIntervento()}}},[_vm._v(" Salva ")]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"outlined":"","type":"error","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.dateError)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }