<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-col cols="12" id="form">
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            v-if="
              updateAll != null ||
              (editedIntervento.ricorrenza != 2 &&
                editedIntervento.ricorrenza != 3)
            "
            >Modifica intervento</span
          >
        </v-card-title>

        <v-card-text>
          <div
            v-if="
              editedIntervento.ricorrenza == 2 ||
              editedIntervento.ricorrenza == 3
            "
            :class="
              updateAll == null && !intGestiti
                ? 'd-flex justify-space-between align-center flex-wrap'
                : 'd-flex align-center flex-wrap'
            "
          >
            <span class="text-h6">Intervento ricorrente: </span>
            <v-btn
              small
              color="primary"
              dark
              @click="tipoModifica(false)"
              v-if="updateAll == null"
              :class="updateAll == null && !intGestiti ? '' : 'ml-2'"
            >
              Modifica singolo intervento
            </v-btn>
            <v-btn
              small
              color="primary"
              dark
              @click="tipoModifica(true)"
              v-if="updateAll == null && !intGestiti"
              :class="$vuetify.breakpoint.xs ? 'mt-3' : ''"
            >
              Modifica tutti gli interventi
            </v-btn>
          </div>
          <v-form
            ref="form"
            v-model="valid"
            v-if="
              updateAll != null ||
              (editedIntervento.ricorrenza != 2 &&
                editedIntervento.ricorrenza != 3)
            "
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="tipologie"
                    item-text="nome"
                    item-value="Id"
                    :item-disabled="checkType()"
                    label="Tipo intervento"
                    v-model="editedIntervento.id_tipo_int"
                    :rules="[(v) => !!v || 'Campo obbligatorio']"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    editedIntervento.id_tipo_int != 7 &&
                    editedIntervento.id_tipo_int != 8
                  "
                >
                  <v-select
                    :items="clienti"
                    item-text="text"
                    item-value="id"
                    label="Cliente"
                    v-model="editedIntervento.id_cliente"
                    :rules="[(v) => !!v || 'Campo obbligatorio']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedIntervento.data_inizio"
                        label="Inizio intervento"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedIntervento.data_inizio"
                      @input="menu1 = false"
                      :min="
                        editedIntervento.data_termine && !updateAll
                          ? editedIntervento.data_termine
                          : currentDate
                      "
                      :max="
                        editedIntervento.data_termine
                          ? editedIntervento.data_termine
                          : ''
                      "
                      locale="it-IT"
                      first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedIntervento.ora_inizio"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedIntervento.ora_inizio"
                        label="Ora inizio"
                        prepend-icon="mdi-clock-time-four-outline"
                        v-bind="attrs"
                        readonly
                        v-on="on"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="editedIntervento.ora_inizio"
                      full-width
                      @click:minute="
                        $refs.menu2.save(editedIntervento.ora_inizio)
                      "
                      :max="editedIntervento.ora_termine"
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  editedIntervento.id_tipo_int != 7 &&
                  editedIntervento.id_tipo_int != 8 &&
                  updateAll
                "
              >
                <v-col cols="12" sm="4">
                  <v-select
                    :items="ricorrenze"
                    item-text="text"
                    item-value="value"
                    label="Ricorrenze"
                    :item-disabled="checkType()"
                    v-model="editedIntervento.ricorrenza"
                    @change="editedIntervento.giorni = []"
                    :rules="
                      editedIntervento.id_tipo_int != 7 &&
                      editedIntervento.id_tipo_int != 8
                        ? [(v) => !!v || 'Campo obbligatorio']
                        : []
                    "
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  v-if="
                    editedIntervento.ricorrenza == 2 ||
                    editedIntervento.ricorrenza == 3
                  "
                >
                  <v-card-text>
                    <span class="subheading">Si ripete il</span>

                    <v-chip-group
                      v-model="editedIntervento.giorni"
                      active-class="deep-purple--text text--accent-4"
                      mandatory
                      :multiple="
                        editedIntervento.ricorrenza == 3 ? true : false
                      "
                    >
                      <v-chip
                        v-for="(giorno, i) in giorni"
                        :key="i"
                        :value="giorno.value"
                      >
                        {{ giorno.text }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedIntervento.data_termine"
                        label="Fine intervento"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :clearable="true"
                        readonly
                        :rules="
                          !editedIntervento.ora_termine &&
                          editedIntervento.id_tipo_int != 7 &&
                          editedIntervento.id_tipo_int != 8 &&
                          (!updateAll ||
                            (editedIntervento.ricorrenza != 2 &&
                              editedIntervento.ricorrenza != 3 &&
                              updateAll))
                            ? []
                            : [(v) => !!v || 'Campo obbligatorio']
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedIntervento.data_termine"
                      @input="menu3 = false"
                      :min="editedIntervento.data_inizio"
                      :max="
                        editedIntervento.id_tipo_int != 7 &&
                        editedIntervento.id_tipo_int != 8 &&
                        (!updateAll ||
                          (editedIntervento.ricorrenza != 2 &&
                            editedIntervento.ricorrenza != 3 &&
                            updateAll))
                          ? editedIntervento.data_inizio
                          : ''
                      "
                      locale="it-IT"
                      first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedIntervento.ora_termine"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedIntervento.ora_termine"
                        label="Ora fine"
                        prepend-icon="mdi-clock-time-four-outline"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        :clearable="true"
                        :rules="
                          !editedIntervento.data_termine
                            ? []
                            : [(v) => !!v || 'Campo obbligatorio']
                        "
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu4"
                      v-model="editedIntervento.ora_termine"
                      full-width
                      format="24hr"
                      @click:minute="
                        $refs.menu4.save(editedIntervento.ora_termine)
                      "
                      :min="
                        editedIntervento.data_inizio ==
                          editedIntervento.data_termine ||
                        editedIntervento.ricorrenza == 2 ||
                        editedIntervento.ricorrenza == 3 ||
                        editedIntervento.ora_inizio
                          ? editedIntervento.ora_inizio
                          : ''
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row
                v-for="(utente, i) in editedIntervento.dipendenti"
                :key="i"
              >
                <v-col cols="12" sm="6">
                  <v-select
                    @input="
                      editedIntervento = Object.assign({}, editedIntervento)
                    "
                    :items="utentiNomi"
                    item-text="nome"
                    item-value="id"
                    label="Dipendente"
                    v-model="editedIntervento.dipendenti[i].id_utente"
                    :rules="
                      editedIntervento.id_tipo_int != 7 &&
                      editedIntervento.id_tipo_int != 8
                        ? [
                            checkSelection(
                              editedIntervento.dipendenti[i].id_utente,
                              i
                            ) || 'Dipendete è stato selezionato più volte',
                          ]
                        : [(v) => !!v || 'Campo obbligatorio']
                    "
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  v-if="
                    editedIntervento.id_tipo_int != 7 &&
                    editedIntervento.id_tipo_int != 8
                  "
                >
                  <v-select
                    :items="categorie"
                    item-text="categoria"
                    item-value="Id"
                    label="Categoria"
                    v-model="editedIntervento.dipendenti[i].id_categoria"
                    :disabled="!editedIntervento.dipendenti[i].id_utente"
                    :rules="
                      !editedIntervento.dipendenti[i].id_utente
                        ? []
                        : [
                            (v) =>
                              !!v || 'Seleziona una categoria per dipendente',
                          ]
                    "
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="d-flex align-center"
                  v-if="
                    editedIntervento.id_tipo_int != 7 &&
                    editedIntervento.id_tipo_int != 8
                  "
                >
                  <v-btn
                    class="ma-2"
                    x-small
                    outlined
                    fab
                    color="primary"
                    @click="removeUtente(i)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                v-if="
                  editedIntervento.id_tipo_int != 7 &&
                  editedIntervento.id_tipo_int != 8
                "
              >
                <v-col cols="12" sm="4">
                  <v-btn small color="primary" dark @click="aggiungiUtente()">
                    Aggiungi dipendente
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-textarea
                    :clearable="true"
                    outlined
                    clear-icon="mdi-close-circle"
                    label="Note"
                    v-model="editedIntervento.note_admin"
                    maxlength="255"
                    counter="255"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()"> Chiudi </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!valid"
            :loading="buttonLoading"
            @click="saveIntervento()"
            v-if="
              updateAll != null ||
              (editedIntervento.ricorrenza != 2 &&
                editedIntervento.ricorrenza != 3)
            "
          >
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- ALERT DI ERRORE DATA/ORA -->
      <v-dialog v-model="errorDialog" max-width="500px">
        <v-alert outlined type="error" elevation="2" class="mb-0">
          {{ dateError }}
        </v-alert>
      </v-dialog>
    </v-col>
  </v-dialog>
</template>

<script>
export default {
  name: "CreaIntervento",
  data() {
    return {
      clienti: [],
      interventi: [],
      currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      tipologie: [],
      categorie: [],
      buttonLoading: false,
      valid: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      utentiNomi: [],
      dateError: "",
      errorDialog: false,
      editedIntervento: {
        Id: "",
        id_int: "",
        id_cliente: "",
        id_tipo_int: "",
        data_inizio: "",
        ora_inizio: "",
        data_termine: "",
        ora_termine: "",
        dipendenti: [{ id_utente: "", id_categoria: "" }],
        note_admin: "",
        id_riferimento: "",
        ricorrenza: "",
        giorni: [],
      },
      dialog: false,
      ricorrenze: [
        { text: "Non si ripete", value: 1 },
        { text: "Una volta a settimana", value: 2 },
        { text: "Più volte a settimana", value: 3 },
      ],
      giorni: [
        { text: "L", value: 1 },
        { text: "M", value: 2 },
        { text: "M", value: 3 },
        { text: "G", value: 4 },
        { text: "V", value: 5 },
        { text: "S", value: 6 },
        { text: "D", value: 0 },
      ],
      updateAll: null,
      intGestiti: false,
      int_ricorrenti: [],
    };
  },
  mounted() {
    this.$store.state.loading = true;
    this.tipologie = JSON.parse(localStorage.getItem("tipologie"));
    this.categorie = JSON.parse(localStorage.getItem("categorie"));
    this.categorie.forEach((el, i) => {
      if (el.categoria == "Imprevisto") {
        this.categorie.splice(i, 1);
      }
    });
    this.getClientiUtenti();
  },
  methods: {
    getClientiUtenti() {
      this.axios
        .get(`${this.$store.state.url}utenti`, {
          params: {
            id_utente: localStorage.getItem("user"),
            nuovo_int: true,
          },
        })
        .then((response) => {
          this.clienti = response.data.clienti;
          this.utenti = response.data.utenti;
          this.utenti.forEach((el) => {
            var utente = {
              nome: `${el.nome} ${el.cognome}`,
              id: el.id,
            };
            this.utentiNomi.push(utente);
          });
          this.$store.state.loading = false;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    modificaIntervento(item, interventi) {
      this.updateAll = null;
      this.int_ricorrenti = [];
      this.intGestiti = false;
      this.editedIntervento = Object.assign({}, item);
      this.editedIntervento.dipendenti = [{ id_utente: "", id_categoria: "" }];
      this.interventi = interventi;
      interventi.forEach((el) => {
        if (el.id_int == item.id_int) {
          if (
            this.editedIntervento.dipendenti.length == 1 &&
            this.editedIntervento.dipendenti[0].id_utente == "" &&
            this.editedIntervento.dipendenti[0].id_categoria == ""
          ) {
            this.editedIntervento.dipendenti[0].id_utente = el.id_utente;
            this.editedIntervento.dipendenti[0].id_categoria = el.id_categoria;
          } else {
            this.editedIntervento.dipendenti.push({
              id_utente: el.id_utente,
              id_categoria: el.id_categoria,
            });
          }
        }
      });

      if (item.ricorrenza != 2 && item.ricorrenza != 3) {
        if (this.editedIntervento.data_inizio != "") {
          var [day, month, year] = this.editedIntervento.data_inizio.split("/");
          this.editedIntervento.data_inizio = `${year}-${month}-${day}`;
        }
        if (this.editedIntervento.data_termine != "") {
          [day, month, year] = this.editedIntervento.data_termine.split("/");
          this.editedIntervento.data_termine = `${year}-${month}-${day}`;
        }
      } else {
        this.int_ricorrenti.push(this.editedIntervento);
        this.interventi.forEach((el) => {
          if (this.editedIntervento.id_riferimento == "") {
            if (el.id_riferimento == this.editedIntervento.id_int) {
              this.int_ricorrenti.push(el);
            }
          } else {
            if (
              (el.id_riferimento == this.editedIntervento.id_riferimento &&
                el.Id != this.editedIntervento.Id) ||
              el.id_int == this.editedIntervento.id_riferimento
            ) {
              this.int_ricorrenti.push(el);
            }
          }
        });

        this.int_ricorrenti.forEach((el) => {
          if (el.gestito == 1 || el.gestito == 2) {
            this.intGestiti = true;
          }
        });
      }

      if (this.editedIntervento.ora_inizio != "") {
        this.editedIntervento.ora_inizio =
          this.editedIntervento.ora_inizio.slice(0, -3);
      }
      if (this.editedIntervento.ora_termine != "") {
        this.editedIntervento.ora_termine =
          this.editedIntervento.ora_termine.slice(0, -3);
      }

      this.dialog = true;
    },
    tipoModifica(tipo) {
      this.updateAll = tipo;
      if (this.updateAll) {
        var giorni_riccorenti = [];
        var dayOfWeek = [];

        this.int_ricorrenti.forEach((el) => {
          var data = "";
          if (el.data_inizio != "") {
            var [day, month, year] = el.data_inizio.split("/");
            data = `${year}-${month}-${day}`;
            dayOfWeek = new Date(data).getDay();
            if (!giorni_riccorenti.includes(dayOfWeek)) {
              giorni_riccorenti.push(dayOfWeek);
            }
          }
        });

        if (giorni_riccorenti.length == 1) {
          giorni_riccorenti = giorni_riccorenti[0];
        }

        this.int_ricorrenti.sort(function (a, b) {
          [day, month, year] = a.data_inizio.split("/");
          var inizio = `${year}-${month}-${day}`;
          [day, month, year] = b.data_inizio.split("/");
          var termine = `${year}-${month}-${day}`;
          return new Date(inizio) - new Date(termine);
        });

        this.editedIntervento.data_inizio = this.int_ricorrenti[0].data_inizio;
        // this.editedIntervento.ora_inizio = this.int_ricorrenti[0].ora_inizio.slice(0,-3);
        this.editedIntervento.data_termine =
          this.int_ricorrenti[this.int_ricorrenti.length - 1].data_termine;
        // this.editedIntervento.ora_termine =
        //   this.int_ricorrenti[this.int_ricorrenti.length - 1].ora_termine.slice(0,-3);
        this.editedIntervento.giorni = giorni_riccorenti;
      }
      if (this.editedIntervento.data_inizio != "") {
        var [day, month, year] = this.editedIntervento.data_inizio.split("/");
        this.editedIntervento.data_inizio = `${year}-${month}-${day}`;
      }
      if (this.editedIntervento.data_termine != "") {
        [day, month, year] = this.editedIntervento.data_termine.split("/");
        this.editedIntervento.data_termine = `${year}-${month}-${day}`;
      }
    },
    checkType() {
      if (
        this.editedIntervento.ricorrenza == 2 ||
        this.editedIntervento.ricorrenza == 3
      ) {
        this.tipologie.map((el) => {
          if (el.Id == 7 || el.Id == 8) {
            el["disabled"] = true;
          }
        });
        this.ricorrenze.map((el) => {
          if (el.value == 1) {
            el["disabled"] = true;
          }
        });
      } else {
        this.tipologie.map((el) => {
          if (el.Id == 7 || el.Id == 8) {
            el["disabled"] = false;
          }
        });
        this.ricorrenze.map((el) => {
          if (el.value == 1) {
            el["disabled"] = false;
          }
        });
      }
    },
    aggiungiUtente() {
      this.editedIntervento.dipendenti.push({
        id_utente: "",
        id_categoria: "",
      });
      this.editedIntervento = Object.assign({}, this.editedIntervento);
    },
    removeUtente(i) {
      if (this.editedIntervento.dipendenti != undefined) {
        if (this.editedIntervento.dipendenti.length > 1) {
          this.editedIntervento.dipendenti.splice(i, 1);
        } else {
          this.editedIntervento.dipendenti = [
            {
              id_utente: "",
              id_categoria: "",
            },
          ];
        }
      }
      this.editedIntervento = Object.assign({}, this.editedIntervento);
    },
    checkSelection(utente, index) {
      if (utente != "") {
        var found = this.editedIntervento.dipendenti.some(
          (el, i) => el.id_utente == utente && i != index
        );
        if (found) return false;
        return true;
      }
      return true;
    },
    close() {
      this.dialog = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.$nextTick(() => {
        this.editedIntervento = {
          id_int: "",
          id_cliente: "",
          id_tipo_int: "",
          data_inizio: "",
          ora_inizio: "",
          data_termine: "",
          ora_termine: "",
          dipendenti: [{ id_utente: "", id_categoria: "" }],
          note_admin: "",
          ricorrenze: "",
          giorni: [],
        };
        this.updateAll = null;
      });
    },
    saveIntervento() {
      this.buttonLoading = true;
      this.dateError = "";
      var modificaTutti = false;

      // CONTROLLO IL VALORE DELLE DATE/ORE
      if (
        this.editedIntervento.data_inizio == null ||
        this.editedIntervento.data_inizio == undefined
      ) {
        this.editedIntervento.data_inizio = "";
      }
      if (
        this.editedIntervento.data_termine == null ||
        this.editedIntervento.data_termine == undefined
      ) {
        this.editedIntervento.data_termine = "";
      }

      if (
        this.editedIntervento.ora_inizio == null ||
        this.editedIntervento.ora_inizio == undefined
      ) {
        this.editedIntervento.ora_inizio = "";
      }
      if (
        this.editedIntervento.ora_termine == null ||
        this.editedIntervento.ora_termine == undefined
      ) {
        this.editedIntervento.ora_termine = "";
      }

      // CONFRONTO LE DATE DI INIZIO E TERMINE
      if (
        this.editedIntervento.data_termine != "" &&
        this.editedIntervento.id_tipo_int != 7 &&
        this.editedIntervento.id_tipo_int != 8 &&
        this.editedIntervento.ricorrenza != 2 &&
        this.editedIntervento.ricorrenza != 3 &&
        new Date(this.editedIntervento.data_inizio).toLocaleDateString() !=
          new Date(this.editedIntervento.data_termine).toLocaleDateString()
      ) {
        this.dateError =
          "Data di fine deve essere uguale alla data di inizio intervento";
        this.errorDialog = true;
      }

      if (
        (this.editedIntervento.ricorrenza == 2 ||
          this.editedIntervento.ricorrenza == 3) &&
        this.updateAll &&
        new Date(this.editedIntervento.data_inizio).toLocaleDateString() ==
          new Date(this.editedIntervento.data_termine).toLocaleDateString()
      ) {
        this.dateError =
          "Data di fine deve essere maggiore della data di inizio intervento";
        this.errorDialog = true;
      }

      console.log(
        new Date(this.editedIntervento.data_inizio).toLocaleTimeString(),
        new Date(this.editedIntervento.data_termine).toLocaleTimeString()
      );

      if (
        (this.editedIntervento.ricorrenza == 2 ||
          this.editedIntervento.ricorrenza == 3) &&
        new Date(this.editedIntervento.data_inizio).toLocaleDateString() !=
          new Date(this.editedIntervento.data_termine).toLocaleDateString() &&
        this.editedIntervento.ora_inizio == this.editedIntervento.ora_termine
      ) {
        this.dateError =
          "Ora di fine deve essere maggiore dell'ora di inizio intervento";
        this.errorDialog = true;
      }

      // CREO LA STRINGA PER POTER CONFRONTARE INIZIO E TERMINE (ORARIO INCLUSO)
      var inizio = this.editedIntervento.data_inizio.concat(
        " ",
        this.editedIntervento.ora_inizio
      );
      var termine = this.editedIntervento.data_termine.concat(
        " ",
        this.editedIntervento.ora_termine
      );

      if (new Date(inizio) >= new Date(termine)) {
        this.dateError =
          "Data e ora di inizio deve essere minore della data e ora di fine intervento";
        this.errorDialog = true;
      }

      if (
        (this.editedIntervento.ricorrenza == 2 ||
          this.editedIntervento.ricorrenza == 3) &&
        this.updateAll
      ) {
        var dayOfWeekStart = new Date(
          this.editedIntervento.data_inizio
        ).getDay();
        var dayOfWeekEnd = new Date(
          this.editedIntervento.data_termine
        ).getDay();
        if (this.editedIntervento.ricorrenza == 3) {
          this.editedIntervento.giorni.sort();
        }

        if (
          this.editedIntervento.ricorrenza == 2 &&
          this.editedIntervento.giorni != dayOfWeekStart
        ) {
          this.dateError =
            "Giorno della settimana selezionato non corrisponde alla data di inizio";
          this.errorDialog = true;
        } else if (
          this.editedIntervento.ricorrenza == 2 &&
          this.editedIntervento.giorni != dayOfWeekEnd
        ) {
          this.dateError =
            "Giorno della settimana selezionato non corrisponde alla data di fine";
          this.errorDialog = true;
        } else if (
          this.editedIntervento.ricorrenza == 3 &&
          !this.editedIntervento.giorni.includes(dayOfWeekStart)
        ) {
          this.dateError =
            "Data di inizio non corrisponde a nessuno dei giorni della settimana selezionati";
          this.errorDialog = true;
        } else if (
          this.editedIntervento.ricorrenza == 3 &&
          !this.editedIntervento.giorni.includes(dayOfWeekEnd)
        ) {
          this.dateError =
            "Data di fine non corrisponde a nessuno dei giorni della settimana selezionati";
          this.errorDialog = true;
        }
      }

      if (!this.errorDialog) {
        if (this.editedIntervento.ricorrenza == 2) {
          this.editedIntervento.giorni = [this.editedIntervento.giorni];
        }
        // CONTROLLO I VALORI DEI DIPENDETI/CATEGORIE
        if (
          this.editedIntervento.id_tipo_int != 7 &&
          this.editedIntervento.id_tipo_int != 8
        ) {
          var indexes = [];
          this.editedIntervento.dipendenti.forEach((el) => {
            if (
              el.id_utente == null ||
              el.id_utente == "" ||
              el.id_categoria == null ||
              el.id_categoria == ""
            ) {
              var index = this.editedIntervento.dipendenti.indexOf(el);
              if (this.editedIntervento.dipendenti.length > 1) {
                indexes.push(index);
              } else {
                this.editedIntervento.dipendenti = [
                  { id_utente: 0, id_categoria: 0 },
                ];
              }
            }
          });
          indexes = indexes.reverse();
          for (let i = 0; i < indexes.length; i++) {
            this.editedIntervento.dipendenti.splice(indexes[i], 1);
          }
          if (this.updateAll) {
            modificaTutti = true;
          }
        } else {
          this.editedIntervento.dipendenti[0].id_categoria = null;
          this.editedIntervento.id_cliente = null;
          this.editedIntervento.ricorrenza = 1;
          this.editedIntervento.giorni = null;
        }

        this.axios
          .post(`${this.$store.state.url}interventi`, {
            id_utente: localStorage.getItem("user"),
            nuovo_int: false,
            id_intervento: this.editedIntervento.id_int,
            id_cliente: this.editedIntervento.id_cliente,
            dipendenti: this.editedIntervento.dipendenti,
            id_tipo: this.editedIntervento.id_tipo_int,
            data_inizio:
              this.editedIntervento.data_inizio == ""
                ? null
                : this.editedIntervento.data_inizio,
            ora_inizio:
              this.editedIntervento.ora_inizio == ""
                ? null
                : this.editedIntervento.ora_inizio,
            data_termine:
              this.editedIntervento.data_termine == ""
                ? null
                : this.editedIntervento.data_termine,
            ora_termine:
              this.editedIntervento.ora_termine == ""
                ? null
                : this.editedIntervento.ora_termine,
            note:
              this.editedIntervento.note_admin == undefined ||
              this.editedIntervento.note_admin == ""
                ? null
                : this.editedIntervento.note_admin,
            ricorrenze:
              this.editedIntervento.ricorrenza == undefined ||
              this.editedIntervento.ricorrenza == ""
                ? null
                : this.editedIntervento.ricorrenza,
            giorni:
              this.editedIntervento.giorni == undefined ||
              this.editedIntervento.giorni == ""
                ? null
                : this.editedIntervento.giorni,
            id_riferimento:
              this.editedIntervento.id_riferimento == undefined ||
              this.editedIntervento.id_riferimento == ""
                ? null
                : this.editedIntervento.id_riferimento,
            modifica_tutti: modificaTutti,
            source: "web",
          })
          .then(() => {
            this.buttonLoading = false;
            this.$emit("get-interventi");
            this.$root.Snackbar.show({
              text: `L'intervento è stato modificato`,
            });
            this.close();
          })
          .catch((error) => {
            this.buttonLoading = false;
            this.close();
            if (error.response != undefined) {
              if (error.response.status == 401) {
                this.$root.$emit("logout", error.response.data.message);
              }
            }
          });
      }
      this.buttonLoading = false;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style>
.v-alert--outlined {
  background-color: white !important;
}

#form {
  padding: 0 !important;
}

#form div.v-card.v-sheet.theme--light {
  border-radius: 0 !important;
}
</style>